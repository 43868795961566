import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
    username: string;
    token: string;
}

const initialState: UserState = {
    username: '',
    token: '',
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<UserState>) => {
            state.username = action.payload.username;
            state.token = action.payload.token;
        },
        clearUser: (state) => {
            state.username = '';
            state.token = '';
        },
    },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
