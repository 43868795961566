import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/userSlice';
import api from '../../api';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await api.post('/login', { username, password });
            setMessage(response.data.message);
            dispatch(setUser({ username, token: response.data.token }));

            // Redirect based on first time login
            if (response.data.tempPassword) {
                navigate('/change-password');
            } else {
                navigate('/fines');
            }
        } catch (error) {
            console.error('Error during login:', error);
            // @ts-ignore
            if (error.response && error.response.data) {
                // @ts-ignore
                setMessage('Failed to login: ' + error.response.data.error);
            } else {
                // @ts-ignore
                setMessage('Failed to login: ' + error.message);
            }
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Username:
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <button type="submit">Login</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Login;
