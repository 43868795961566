import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLaws } from '../../store/lawsSlice';
import api from '../../api';
import { RootState } from '../../store';

const LawPage: React.FC = () => {
    const dispatch = useDispatch();
    const laws = useSelector((state: RootState) => state.laws.laws);

    useEffect(() => {
        api.get('/laws')
            .then(response => {
                dispatch(setLaws(response.data));
            })
            .catch(error => {
                console.error('There was an error fetching the laws!', error);
            });
    }, [dispatch]);

    return (
        <div>
            <h1>Law Overview</h1>
            <ul>
                {laws.map(law => (
                    <li key={law.id}>
                        <h2>{law.paragraph}</h2>
                        <p>{law.description}</p>
                        <p>Fine amount: {law.fine_amount}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LawPage;
