import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { MainPage } from './components/MainPage';
import LawPage from './components/fines/LawPage';
import FinePage from './components/fines/FinePage';
import Login from './components/fines/Login';
import ChangePassword from './components/fines/ChangePassword';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/fines/FinesNavbar';

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <ConditionalNavbar />
                    <Routes>
                        <Route path="/" element={<MainPage />} />
                        <Route path="/login" element={<Login />} />
                        <Route element={<ProtectedRoute />}>
                            <Route path="/laws" element={<LawPage />} />
                            <Route path="/fines" element={<FinePage />} />
                            <Route path="/change-password" element={<ChangePassword />} />
                        </Route>
                    </Routes>
                </Router>
            </PersistGate>
        </Provider>
    );
}

const ConditionalNavbar = () => {
    const location = useLocation();
    if (location.pathname === '/fines' || location.pathname === '/laws') {
        return <Navbar />;
    }
    return null;
};

export default App;
