import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import api from '../../api';
import { setFines } from '../../store/finesSlice';
import { setLaws } from '../../store/lawsSlice';
import '../css/finepage.css';

const FinePage: React.FC = () => {
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedLaw, setSelectedLaw] = useState('');
    const [fineCount, setFineCount] = useState(1);
    const [description, setDescription] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const fines = useSelector((state: RootState) => state.fines.fines);
    const laws = useSelector((state: RootState) => state.laws.laws);
    const [users, setUsers] = useState<{ ID: number; Username: string }[]>([]);

    useEffect(() => {
        api.get('/users')
            .then(response => {
                console.log('Fetched users:', response.data);
                setUsers(response.data);
            })
            .catch(error => console.error('Error fetching users:', error));

        api.get('/laws')
            .then(response => dispatch(setLaws(response.data)))
            .catch(error => console.error('Error fetching laws:', error));

        api.get('/fines')
            .then(response => dispatch(setFines(response.data)))
            .catch(error => console.error('Error fetching fines:', error));
    }, [dispatch]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        let imageURL = '';

        if (image) {
            const formData = new FormData();
            formData.append('image', image);

            try {
                const response = await api.post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                imageURL = response.data.url;
            } catch (error) {
                console.error('Error uploading image:', error);
                setMessage('Error uploading image');
                return;
            }
        }

        const fineData = {
            user_id: parseInt(selectedUser),
            law_id: parseInt(selectedLaw),
            fine_count: fineCount,
            description: description,
            image_url: imageURL
        };

        console.log('Submitting fine:', fineData);

        try {
            const response = await api.post('/fines', fineData);
            setMessage('Fine submitted successfully');
            setShowModal(false);
            dispatch(setFines([...fines, response.data]));
        } catch (error) {
            console.error('Error submitting fine:', error);
            // @ts-ignore
            if (error.response && error.response.data) {
                // @ts-ignore
                setMessage('Error submitting fine: ' + error.response.data.error);
            } else {
                // @ts-ignore
                setMessage('Error submitting fine: ' + error.message);
            }
        }
    };

    const getUserById = (userId: number) => {
        const user = users.find(user => user.ID === userId);
        return user ? user.Username : 'Unknown User';
    };

    const getLawById = (lawId: number) => {
        const law = laws.find(law => law.id === lawId);
        return law ? law.paragraph : 'Unknown Law';
    };

    return (
        <div>
            <h1>Fines</h1>
            <button onClick={() => setShowModal(true)}>Report Fine</button>
            {fines.map(fine => (
                <div key={fine.id} className="fine">
                    <p>User: {getUserById(fine.user_id)}</p>
                    <p>Law: {getLawById(fine.law_id)}</p>
                    <p>Count: {fine.fine_count}</p>
                    <p>Description: {fine.description}</p>
                    {fine.image_url && <img src={`http://localhost:8080${fine.image_url}`} alt="evidence" />}
                </div>
            ))}

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Report a Fine</h2>
                        <form onSubmit={handleSubmit}>
                            <label>
                                User:
                                <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                                    {users.map(user => (
                                        <option key={user.ID} value={user.ID}>{user.Username}</option>
                                    ))}
                                </select>
                            </label>
                            <label>
                                Law:
                                <select value={selectedLaw} onChange={(e) => setSelectedLaw(e.target.value)}>
                                    {laws.map(law => (
                                        <option key={law.id} value={law.id}>{law.paragraph}</option>
                                    ))}
                                </select>
                            </label>
                            <label>
                                Fine Count:
                                <input type="number" value={fineCount} onChange={(e) => setFineCount(parseInt(e.target.value))} />
                            </label>
                            <label>
                                Description:
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                            </label>
                            <label>
                                Image:
                                <input type="file" onChange={(e) => setImage(e.target.files ? e.target.files[0] : null)} />
                            </label>
                            <button type="submit">Submit Fine</button>
                        </form>
                        <button onClick={() => setShowModal(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FinePage;
