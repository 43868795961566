import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import api from '../../api';
import { useNavigate } from 'react-router-dom';

const ChangePassword: React.FC = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const username = useSelector((state: RootState) => state.user.username);
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await api.post('/change-password', {
                username,
                old_password: oldPassword,
                new_password: newPassword,
            });
            setMessage(response.data.message);
            navigate('/fines')
        } catch (error) {
            // @ts-ignore
            setMessage('Failed to change password: ' + error.response.data.error);
        }
    };

    return (
        <div>
            <h1>Change Password</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Old Password:
                    <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                </label>
                <label>
                    New Password:
                    <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </label>
                <button type="submit">Change Password</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ChangePassword;
