import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Law {
    id: number;
    paragraph: string;
    description: string;
    fine_amount: number;
}

interface LawsState {
    laws: Law[];
}

const initialState: LawsState = {
    laws: [],
};

const lawsSlice = createSlice({
    name: 'laws',
    initialState,
    reducers: {
        setLaws: (state, action: PayloadAction<Law[]>) => {
            state.laws = action.payload;
        },
    },
});

export const { setLaws } = lawsSlice.actions;

export default lawsSlice.reducer;
