import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Fine {
    id: number;
    user_id: number;
    law_id: number;
    fine_count: number;
    description: string;
    image_url: string;
}

interface FinesState {
    fines: Fine[];
}

const initialState: FinesState = {
    fines: [],
};

const finesSlice = createSlice({
    name: 'fines',
    initialState,
    reducers: {
        setFines: (state, action: PayloadAction<Fine[]>) => {
            state.fines = action.payload;
        },
    },
});

export const { setFines } = finesSlice.actions;

export default finesSlice.reducer;
